import apiHttp from '@api/apiHttp'

const state = {
  sensors: [],
  serverTime: new Date().getTime(), //time provisório até receber o time do servidor
  status:undefined
}


const getters = {
  getById: (state)=> (id) => {
    return state.sensors.find(ele => ele.id === id)
  },
  pluvioList: state => {
    return state.sensors.filter(ele => ele.type === 'pluviometer');
  },
  waterList: state => {
    return state.sensors.filter(ele => ele.type === 'waterLevel');
  },
  wellList: state => {
    return state.sensors.filter(ele => ele.type === 'well');
  },
  hydrometerList: state => {
    return state.sensors.filter(ele => ele.type === 'hydrometer');
  },
  stationsList: state => {
    return state.sensors.filter(ele => ele.type === 'station');
  },
  pivotsList: state =>{
    return state.sensors.filter(ele => ele.type === 'pivot');
  },
  soilsList: state =>{
    return state.sensors.filter(ele => ele.type === 'soil');
  },
  getSensByEquipId: (state)=> (id) => {
    return state.sensors.find((ele) =>  ele.equipmentId === id)
  },
  getListSensByIds: (state) => (ids) =>{
    return state.sensors.filter(ele => ids.indexOf(ele.id) != -1);
  }
}

const actions = {
  loadSensors(context) {
    context.commit('setStatus','loading' )
    context.commit('setAll', []);

    return new Promise((resolve, reject) => {
      apiHttp.get('sens/sensor').then((data) => {
        context.commit('setAll', data);
        context.commit('setStatus','loaded' )
        resolve(data);
      }, (error) => {
        context.commit('setStatus','error' )
        reject(error);
      });
    })
  },

  updateSensors(context) {
    return new Promise((resolve, reject) => {
      apiHttp.get('sens/sensor').then((data) => {
        context.commit('setAll', data);
        context.commit('setStatus','loaded' )
        resolve(data);
      }, (error) => {
        context.commit('setStatus','error' )
        reject(error);
      });
    })
  },
}

const mutations = {
  setStatus(state,value){
    state.status = value
  },

  setAll (state, sensors) {
    state.sensors = sensors
  //    console.log("set sensors", state.sensors )
  },

  setServerTime(state,time){
    state.serverTime = time
  },

  updateSensor(state,sensor){
      if(sensor.id != null){
          const index = state.sensors.findIndex((e) => e.id === sensor.id);
          if (index === -1) {
                state.sensors.push(sensor);
              //  console.log("push sensor")
            } else {
            //    console.log("update sensor")
                let dataObj = state.sensors[index];
                Object.entries(sensor).forEach(([key, value]) => {
                  dataObj[key] = value;
                });
            }
      }
  }


}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
