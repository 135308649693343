<template lang="html">
  <svg :width="width" :height="height" viewBox="0 0 150 150">
    <g transform="translate(0,97.083333)" style="display:inline" id="layer2">
      <path
        id="terra"
        d="M 136.92068,-72.118924 V 31.725422 c 0,11.511834 -9.26765,20.779503 -20.77949,20.779503 H 36.159457 c -11.511831,0 -20.779479,-9.267669 -20.779479,-20.779503 V -72.118924 Z"
        style="opacity:0.98999999;fill:#d38d5f;stroke:#d38d5f;stroke-width:0.33134487;stroke-linecap:round;paint-order:markers stroke fill"
      />
      <path
        style="opacity:0.98999999;fill:#deaa87;stroke:none;stroke-width:0.77708453;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
        d="m 51.086983,30.599937 c -3e-6,2.159108 -1.67706,3.909427 -3.745813,3.909427 -2.068755,0 -3.74581,-1.750319 -3.745812,-3.909427 2e-6,-2.159137 2.033617,-2.793054 3.389255,-1.995628 1.80223,1.060122 4.102367,-0.163481 4.10237,1.995628 z"
        id="path4640"
      />
      <g transform="translate(-20.10834)" id="cano">
        <rect
          ry="0"
          y="-52.518753"
          x="63.022961"
          height="96.821762"
          width="30.613605"
          id="rect4544"
          style="opacity:0.98999999;fill:#e3dedb;stroke:#d38d5f;stroke-width:0.39224172;stroke-linecap:round;paint-order:markers stroke fill"
        />
        <rect
          style="opacity:0.98999999;fill:#93aca7;stroke:#d38d5f;stroke-width:0.12273686;stroke-linecap:round;paint-order:markers stroke fill"
          id="rect4555"
          width="30.905745"
          height="9.3905382"
          x="62.876888"
          y="-58.962734"
          ry="0"
        />
        <g id="g5142">
          <rect
            ry="0"
            y="-86.709465"
            x="55.150345"
            height="28.445919"
            width="46.358845"
            id="rect4542"
            style="opacity:0.98999999;fill:#e3dedb;stroke:none;stroke-width:0.26162928;stroke-linecap:round;paint-order:markers stroke fill"
          />
          <rect
            style="opacity:0.98999999;fill:#b7c8b7;stroke:none;stroke-width:0.15425998;stroke-linecap:round;paint-order:markers stroke fill"
            id="rect4559"
            width="16.116339"
            height="28.445919"
            x="85.392853"
            y="-86.709465"
            ry="0"
          />
        </g>

      </g>


      <rect
       style="opacity:1;fill:#008000;fill-opacity:1;stroke:none;stroke-width:1.65986896;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
       id="grama"
       width="122.0863"
       height="13.985119"
       x="15.119047"
       y="-81.264885" />
      <g
        transform="translate(-20.10834,-244.08334)"
        id="g5153"
        style="display:inline"
      >
        <path
          style="fill:#808080;stroke:#666666;stroke-width:1.11579728;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          d="m 93.61512,208.0813 h 6.46065"
          id="path4569"
        />
        <path
          id="path4662"
          d="M 93.61512,208.0813 H 63.096187"
          style="fill:#999999;stroke:#cccccc;stroke-width:1.01715398;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        />
      </g>
      <path
        style="display:inline;fill:none;stroke:#000000;stroke-width:0.83840609px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
        d="M 73.52822,288.38628 H 79.8801"
        id="path4575"
        transform="translate(0,-244.08334)"
      />
    </g>

    

    <g style="display:inline" transform="translate(0,-147.00001)" id="layer1">
      <g id="g5424">
        <rect
          style="opacity:0.98999999;fill:#2c89a0;stroke:none;stroke-width:0.27172196;stroke-linecap:round;paint-order:markers stroke fill"
          id="agua"
          width="29.855873"
          :height="waterY"
          x="43.293472"
          :y="288-waterY"
          ry="0"
        />
        <text
          xml:space="preserve"
          style="font-style:normal;font-weight:normal;font-size:16.33833504px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#4d4d4d;fill-opacity:1;stroke:none;stroke-width:0.40845832"
          id="agua_level"
          transform="scale(0.93545784,1.0689953)"
        >
          <tspan
            id="tspan4561"
            x="61.8936"
            :y="268-waterY"
            style="text-align:center;text-anchor:middle;fill:#4d4d4d;stroke-width:0.40845832"
          >
            {{level}}
          </tspan>
        </text>
      </g>
      <text
        xml:space="preserve"
        style="font-style:normal;font-weight:normal;font-size:31.00902367px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.77522546"
        id="agua_max"
      >
        <tspan
          id="tspan4565"
          x="82.069"
          y="208.53416"
          style="font-size:14.40389633px;stroke-width:0.77522546"
        >
          <tspan
            id="tspan5157"
            style="font-size:20.16545677px;line-height:2.25;stroke-width:0.77522546"
          >
            {{maxLevel}}
          </tspan>
          m
        </tspan>
      </text>
      <text
        id="agua_min"
        style="font-style:normal;font-weight:normal;font-size:31.76894188px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.79422343"
        xml:space="preserve"
      >
        <tspan
          style="font-size:14.40389633px;stroke-width:0.77522546"
          y="288.83542"
          x="82.069"
          id="tspan4571"
        >
          <tspan
            id="tspan5165"
            style="font-size:20.16545677px;line-height:2.25;stroke-width:0.77522546"
          >
            {{sensorLevel}}
          </tspan>
          m
        </tspan>
      </text>
    </g>

    <g transform="scale(2.5,2.5) translate(-20,7)">
      <alertSvg v-if="isOnline && level==null"></alertSvg>
    </g>

  </svg>
</template>

<script>
import sensWell from "./sensWell.js";
import alertSvg from '../alert.vue'
export default {
  extends: sensWell,
  props: {
    width: { type: [Number, String], required: false, default: 100 },
    height: { type: [Number, String], required: false, default: 100 },
    sensor: { type: Object, default: undefined },
  },

  components:{
    alertSvg
  },

  methods: {
    map(x, in_min, in_max, out_min, out_max) {
      return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    },
  },
  computed: {
    waterY() {
      let val = this.map(this.level,this.maxLevel,this.sensorLevel,80,0);
      if(val < 0) val = 0;
      if(val> 80) val =80;
      return val;
    },
  },
};
</script>

