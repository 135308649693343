<template>
  <b-card>
    <div class="d-flex mb-2">
      <h5>{{ dataStream.sensorId }} - {{ dataStream.name }}</h5>
      <b-button-toolbar class="ml-auto">
        <b-button-group size="sm" class="mx-1">
          <b-button
            @click="loadDataPoints()"
            variant="outline-secondary"
            title="recarregar"
          >
            <font-awesome-icon icon="sync-alt" />
          </b-button>
          <b-dropdown variant="outline-secondary" right text="menu">
            <b-dropdown-item @click="mode = 'edit'">Editar</b-dropdown-item>
            <b-dropdown-item @click="clearAllData()">Limpar</b-dropdown-item>
            <b-dropdown-item @click="$emit('removedatastream', dataStream.id)"
              >Remover</b-dropdown-item
            >
            <b-dropdown-item @click="downloadJson()" >Download Json</b-dropdown-item >
            <b-dropdown-item @click="downloadExcel()" >Download Excel</b-dropdown-item >

              

            <b-dropdown-item :disabled="rangeselect == undefined" @click="clearRangeData(rangeselect)">Apagar faixa de dados</b-dropdown-item>
            <b-dropdown-item :disabled="rangeselect == undefined" @click="mode = 'dataModifier'" >Modificar Dados</b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <hr />
    <!-- GRAFICOS -->
    <div v-if="mode == 'chart'">
      <div v-if="loadStatus == 'loaded'">
        <div class="d-flex current-value" v-if="data.currentValue">
          <span class="value">
            {{ data.currentValue.v1 }}
            {{ data.currentValue.v2 }}
            <small> {{ dataStream.unitSymbol }} </small>
          </span>
          <span class="last-update ml-auto">
            <font-awesome-icon icon="clock" />
            {{ $filters.localeDateTime(data.currentValue.at) }}
          </span>
        </div>

        <!-- datapoints -->
        <div v-if="data.dataPoints">
          <dataStreamLocations
            :dataStream="dataStream"
            :datapoints="data.dataPoints"
            v-if="dataStream.dataType == 'location'"
          />
          <dataStreamChart
            v-else
            :dataStream="dataStream"
            :datapoints="data.dataPoints"
            :rangeselect.sync="rangeselect"
          />
        </div>
        <!-- se não tiver datapoints -->
        <b-alert v-else show variant="warning">
          <div class="d-flex">
            <div class="mr-2" style="font-size:35px;">
              <font-awesome-icon icon="exclamation-triangle" />
            </div>
            <div><p>Sem dados no período.</p></div>
          </div>
        </b-alert>
      </div>
      <c-loading :status="loadStatus" v-else></c-loading>
      <hr />
      <div class="d-flex">
        <div class="ml-auto">
          <data-range v-model="dateRange"/>
        </div>
      </div>
      <!-- range:{{rangeselect}} -->
    </div>

    <!-- EDITAR -->
    <div v-if="mode == 'edit'">
      <dataStreamEdit
        :dataStreamId="dataStream.id"
        @chartmode="mode = 'chart'"
        @updatedatastream="$emit('updatedatastream', $event)"
      />
    </div>

     <!-- Operações -->
    <div v-if="mode == 'dataModifier'">
      <dataStreamDataModifier
        :dataStreamId="dataStream.id"
        @chartmode="mode = 'chart'; loadDataPoints()"
        :rangeselect="rangeselect"
      />
    </div>


  </b-card>
</template>

<script>
import { DateTime } from 'luxon';
import dataStreamChart from "./dataStreamChart";
import dataStreamLocations from "./dataStreamLocations";
import dataStreamEdit from "./dataStreamEdit";
import dataStreamDataModifier from "./dataStreamDataModifier";
import writeXlsxFile from 'denver-write-excel-file'


var msgBoxConfirmOptions = {
  title: "Por favor confirme",
  size: "sm",
  buttonSize: "sm",
  okVariant: "danger",
  okTitle: "Sim",
  cancelTitle: "Não",
  footerClass: "p-2",
  hideHeaderClose: false,
  centered: true,
};

export default {
  data() {
    return {
      form: undefined,
      data: undefined,
      loadStatus: "loading",
      dateRange: null,
      mode: "chart",
      rangeselect:undefined
    };
  },
  props: {
    dataStream: { type: Object, require: true },
    feed: { type: Object, require: true },
  },

  mounted() {
    let start = DateTime.local().startOf('day').toJSDate();
    let end = DateTime.local().endOf('day').toJSDate();
    this.dateRange = [start, end];
    this.form = JSON.parse(JSON.stringify(this.dataStream));
  },

  methods: {

    loadDataPoints() {
      this.loadStatus = "loading";
      this.mode = "chart";
      let start = DateTime.fromJSDate(this.dateRange[0]).toISO() 
      let end   = DateTime.fromJSDate(this.dateRange[1]).plus({ days: 1 }).toISO()
   
      this.$http
        .get(
          "/sens/dataStream/" +
            this.dataStream.id +
            "/dataPoints/?startTime=" +start +
            "&endTime=" + end +
            "&interval=1&statistics=average"
        )
        .then(
          (data) => {
            this.data = data;
            this.loadStatus = "loaded";
          },
          (error) => {
            console.log("load datapoints error:", error);
            this.loadStatus = "error";
          }
        );
    },

    clearAllData() {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente limpar os dados deste dataStream?",
          msgBoxConfirmOptions
        )
        .then((value) => {
          if (value === true) {
            this.loadStatus = "loading";
            this.$http
              .delete(
                "/sens/dataStream/" + this.dataStream.id + "/dataPoints/clear"
              )
              .then(
                () => {
                  this.loadDataPoints();
                },
                (error) => {
                  console.error("clear dataStream error", error);
                }
              );
          }
        });
    },

    clearRangeData(range){
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente limpar os dados de "
          +DateTime.fromISO(range.start).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
          +" até "
          +DateTime.fromISO(range.end).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)+
          " deste dataStream?",
          msgBoxConfirmOptions
        )
        .then((value) => {
          if (value === true) {
            this.loadStatus = "loading";
            this.$http
              .delete(
                "/sens/dataStream/" + this.dataStream.id + "/dataPoints"+
                "?startTime=" +range.start + "&endTime=" + range.end
              )
              .then(
                () => {
                  this.loadDataPoints();
                },
                (error) => {
                  console.error("clear dataStream error", error);
                }
              );
          }
        });
    },

    downloadJson() {
      let filename = this.feed.name + "_" + this.dataStream.name + ".json";
      let link = document.createElement("a");
      let saveData = JSON.parse(JSON.stringify(this.data));
      saveData.name = this.dataStream.name;
      saveData.sensorId = this.dataStream.sensorId;
      saveData.unitSymbol = this.dataStream.unitSymbol;
      saveData.maxValue = this.dataStream.maxValue;
      saveData.minValue = this.dataStream.minValue;
      saveData.units = this.dataStream.units;
      saveData.dataType = this.dataStream.dataType;
      saveData.currentValue = undefined;
      let json = "data:text/json;charset=utf-8," + JSON.stringify(saveData);
      let data = encodeURI(json);
      link.setAttribute("href", data);
      link.setAttribute("download", filename);
      link.click();
    },



    async downloadExcel() {
          //this.savingFile = true;
          let rows = []
          let headers = []
          let columns =[]
 
          headers.push({ value:'Unix Time Stamp',align:'center', color:"#ffffff", backgroundColor:"#666666",  wrap:true})
          headers.push({ value:'V1',align:'center', color:"#ffffff", backgroundColor:"#666666",  wrap:true})
          headers.push({ value:'V2',align:'center', color:"#ffffff", backgroundColor:"#666666",  wrap:true})


          columns.push({width:20})
          columns.push({width:10})
          columns.push({width:10})
          

          rows.push(headers);


           this.data.dataPoints.forEach((element) => {
              let data = []
              //  data.push({type:Date, value:v, format:'dd/mm/yy HH:MM'})
              data.push({type:Number, value:element.ux})
              data.push({type:Number, value:element.v1})
              data.push({type:Number, value:element.v2})
          //    this.fields.forEach(e=>{
          //       let v = element[e.key];

          //     if(e.exlformatter){
          //       v = e.exlformatter(v)
          //     } else 
          //     if(e.xlsType != undefined && e.xlsType.formatter != undefined){
          //       v = e.xlsType.formatter(v)
          //     }else if (e.formatter) v = e.formatter(v)


          //     if(e.xlsType != undefined ){
          //         data.push({type:e.xlsType.type, value:v,  align:e.xlsType.align, format:e.xlsType.format})

          //     }else{

                  
    
          //         if (v != undefined){
          //           let t = typeof(v)
          //           if(t == 'object')
          //             data.push({type:Date, value:v, format:'dd/mm/yy HH:MM'})
          //             // ws.cell(row,col++).date(v).style({numberFormat: 'dd/mm/yy HH:MM'});
          //           else  if(t == 'number')
          //             data.push({type:Number, value:v,   align:'center'})
          //             // ws.cell(row,col++).number(v);
          //           else
          //             data.push({type:String, value:v,  align:'center'})
          //             // ws.cell(row,col++).string(v);  
          //         }else{
          //           data.push({type:String, value:"",  align:'center'})
          //         }
          //       }
          //   })
             rows.push(data)
           });

          
          

          await writeXlsxFile(rows,{
            columns,
            fileName: this.feed.name + "_" + this.dataStream.name + ".xlsx"
          })

          //this.savingFile = false;

        }


  },
  watch: {
    dateRange() {
      this.loadDataPoints();
    },
  },
  components: {
    dataStreamChart,
    dataStreamEdit,
    dataStreamLocations,
    dataStreamDataModifier
  },
};
</script>

<style lang="scss" scoped>
.current-value {
  .value {
    font-size: 30px;
    font-weight: 600;
    color: #3c8dbc;
  }
  .last-update {
    color: #444;
    line-height: 48px;
    vertical-align: bottom;
  }

}
</style>
