<template>
  <div>
    <vl-layer-vector id="draw-panel" :z-index="zIndex">
      <vl-source-vector
        :features.sync="drawnFeatures"
        :ident="sourceIdent"
      ></vl-source-vector>
       <!-- <vl-style-box>
          <vl-style-stroke color="green"></vl-style-stroke>
          <vl-style-fill color="rgba(255,0,0,0.5)"></vl-style-fill>
        </vl-style-box>  -->
    </vl-layer-vector>

    <vl-interaction-modify :source="sourceIdent"></vl-interaction-modify>

    <vl-interaction-draw
      :source="sourceIdent"
      type="Polygon"
    ></vl-interaction-draw>

    <vl-interaction-snap
      v-if="snap==true"
      :source="sourceIdent"
      :priority="10"
    ></vl-interaction-snap>

    <vl-interaction-snap
      v-if="snap==true && objectsVectorIdent != undefined"
      :source="objectsVectorIdent"
      :priority="9"
    ></vl-interaction-snap>

   

    <div class="editPanel">
      <b-card>
        <b-form-checkbox v-model="snap" name="check-button" switch> snap </b-form-checkbox>
        <hr>
        <div class="areas">
          <div v-for="area,index in areas" :key="index" class="area">
            <span class="mr-3"> <strong>{{area.name}} </strong></span>
            <span class="mr-2">{{ $filters.localeNumber(area.area,2) }} <small>ha</small> </span>
            <b-button @click="removeArea(index)" size="sm" variant="outline-danger">  <font-awesome-icon icon="trash" /> </b-button>
          </div>
        </div>

      </b-card>
    </div>
    <!-- {{drawnFeatures}}
    <br>
    {{value}} -->
  </div>
</template>

<script>
import makeId from '@/utils/makeId'
import calcArea from '@/utils/calcArea'


export default {
  data() {
    return {
      drawnFeatures: [],
      snap: false,
      sourceIdent:"",
    };
  },

  props: {
    objectsVectorIdent:String,
    value: {
      type: Array,
      default: () => {
        [];
      },
    },
    zIndex: { type: Number, default: 3 },
  },

  methods: {
    removeArea(index){
      this.drawnFeatures.splice(index,1)
    },
  },
  beforeMount() {
    this.sourceIdent = "area-source-"+makeId(6);
    this.drawnFeatures = [];
 
    if(this.value){
      let area = JSON.parse(JSON.stringify(this.value));
      if (area) {
        area.forEach((e, i) => {
          this.drawnFeatures.push({
            type: "Feature",
            geometry: { type: "Polygon", coordinates: [e] },
            id: "31ac4169-c545-4664-9b7b-b8ba936203e7" + i,
          });
        });
      }
    }
    // console.log("draw feature", this.drawnFeatures);
  },

  computed:{
    areas(){
      let areas = []

      this.drawnFeatures.forEach((e, i) => {
        areas.push({name:'Area '+(i+1), area:calcArea(e.geometry.coordinates[0])/10000 })
      });


      return areas;
    }

  },


  watch: {
    drawnFeatures(v) {
      // console.log("update feature", v);

      let geometry = [];
      v.forEach((element) => {
        geometry.push(
          JSON.parse(JSON.stringify(element.geometry.coordinates[0]))
        );
      });
      this.$emit("input", geometry);
    },
  },
};
</script>

<style lang="scss" scoped>
.editPanel {
  max-width: 220px;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 3;

  .card-body {
    padding: 0.5rem;
  }

  .areas{
    overflow-y: scroll;
    max-height: 300px;
    padding: 2px;

    .area{
      border-radius: 5px;
      border: 1px solid gray;
      padding: 3px;
      margin-bottom: 3px;
      display: flex;
      
      button{
        margin-left: auto;
        
      }
    }
  }
}
</style>
